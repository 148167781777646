.App {
  text-align: center;
  font-family: Arial, sans-serif;
  padding: 20px;
}

.name-display {
  margin-bottom: 30px;
}

.photo-upload, .voice-component {
  margin: 20px auto;
  width: 300px;
  text-align: left;
}

.photo-upload input, .voice-component textarea {
  width: 100%;
  padding: 8px;
  margin-top: 10px;
}

.photo-preview {
  margin-top: 10px;
}

button {
  margin-top: 10px;
  padding: 10px 20px;
  cursor: pointer;
}
